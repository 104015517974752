
  import {createControllersWithDescriptors, initAppForPageWrapper} from '@wix/yoshi-flow-editor/runtime/esm/viewerScript/wrapper.js';
  
  

  
  var importedApp = {};


  

    var velocycleMobx = null;
    


  

    var blocksControllerService = null;
    

  
var createHttpClient = null;

  

  import { initI18n as initI18n } from '@wix/yoshi-flow-editor/runtime/esm/i18next/init';


  
const multilingualDisabled = false;

  

    var createExperiments = null;
    var createWidgetExperiments = null;
    


  var sentryConfig = {
      DSN: 'https://ed436f5053144538958ad06a5005e99a@sentry.wixpress.com/6142',
      id: 'undefined',
      projectName: 'members-area-notifications--0j',
      teamName: 'ping',
      errorMonitor: true,
    };

  var experimentsConfig = {"scopes":["test"],"centralized":true};

  var translationsConfig = {"icuEnabled":true,"defaultTranslationsPath":"/home/builduser/work/63f463de1bc2bc5f/packages/members-area-notifications-preferences/src/assets/locales/messages_en.json","availableLanguages":["en","uk"]};

  var defaultTranslations = {"app.widget.title":"Notification Settings","app.widget.subtitle":"Choose the notifications you want to get","app.widget.PROMOTIONAL.title":"Marketing Emails","app.widget.TRANSACTIONAL.title":"Transactional Emails ","app.widget.marketing-emails.subscription.title":"Subscribed to All Marketing Emails","app.widget.marketing-emails.subscription.subscribe":"Subscribe","app.widget.marketing-emails.subscription.unsubscribe":"Unsubscribe","app.settings.label":"Settings","app.settings.tabs.design":"Design","app.settings.tabs.design.text-color.title":"Text color","app.settings.tabs.design.title-font.title":"Title text","app.settings.tabs.design.primary-font.title":"Body text","app.settings.tabs.design.button-section.title":"Button style","app.settings.tabs.design.button-text-color.title":"Button text color","app.settings.tabs.design.button-opacity-and-color.title":"Button opacity and color","app.settings.tabs.design.background-section.title":"Backgrounds and borders","app.settings.tabs.design.background-color.title":"Background opacity and color","app.settings.tabs.design.border-color.title":"Border opacity and color","app.settings.tabs.design.border-width.title":"Border width"};

  var fedopsConfig = null;

  import { createVisitorBILogger as biLogger } from '/home/builduser/work/63f463de1bc2bc5f/packages/members-area-notifications-preferences/target/generated/bi/createBILogger.ts';

  export const exports = importedApp.exports;

  export const initAppForPage = initAppForPageWrapper({
    initAppForPage: importedApp.initAppForPage,
    sentryConfig: sentryConfig,
    experimentsConfig: experimentsConfig,
    inEditor: false,
    biLogger: biLogger,
    multilingualDisabled,
    projectName: "members-area-notifications-preferences",
    biConfig: null,
    appName: "Members Notifications Settings",
    appDefinitionId: "14f25dc5-6af3-5420-9568-f9c5ed98c9b1",
    fedopsConfig: fedopsConfig,
    translationsConfig: translationsConfig,
    defaultTranslations: defaultTranslations,
    shouldUseEssentials: true,
    optionalDeps: {
      initI18n,
      createHttpClient,
      createExperiments,
    },
    localeDistPath: "assets/locales",
  });

  const _createControllers = createControllersWithDescriptors({
    initI18n,
    blocksControllerService,
    createHttpClient,
    createExperiments,
    velocycleMobx,
  }, [{ method: null,
          wrap: null,
          exports: null,
          widgetType: "WIDGET_OUT_OF_IFRAME",
          translationsConfig,
          multilingualDisabled,
          experimentsConfig: {"scopes":["test"],"centralized":true},
          fedopsConfig: fedopsConfig,
          sentryConfig: sentryConfig,
          persistentAcrossPages: false,
          biLogger: biLogger,
          shouldUseEssentials: true,
          withErrorBoundary: false,
          biConfig: null,
          controllerFileName: "/home/builduser/work/63f463de1bc2bc5f/packages/members-area-notifications-preferences/src/components/PreferencesOoi/controller.ts",
          appName: "Members Notifications Settings",
          appDefinitionId: "14f25dc5-6af3-5420-9568-f9c5ed98c9b1",
          projectName: "members-area-notifications-preferences",
          componentName: "PreferencesOoi",
          localeDistPath: "assets/locales",
          defaultTranslations: defaultTranslations,
          id: "14f25dd2-f9b0-edc2-f38e-eded5da094aa" }],
    false);

    export const createControllers = _createControllers
